<template>
  <div class="number-label">
    <v-chip small :color="color">
      {{ num }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    num: {
      type: Number,
      required: true
    }
  },

  computed: {
    color() {
      return this.num > 0 ? "primary" : "default";
    }
  }
};
</script>

<style></style>
