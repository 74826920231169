<template>
  <v-dialog v-model="open" scrollable width="600">
    <v-form v-model="valid" v-if="addon" @submit.prevent="save()">
      <v-card>
        <v-card-title class="primary white--text">
          Add-On erfassen
        </v-card-title>

        <v-card-text class="mt-3">
          <v-text-field
            outlined
            dense
            v-model="addon.name"
            label="Name"
            type="text"
            :maxlength="255"
            :rules="textRules"
          ></v-text-field>
          <v-textarea
            outlined
            dense
            v-model="addon.description"
            label="Beschreibung"
          >
          </v-textarea>
          <AmountTextField v-model="addon.price" label="Preis" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="abort()">Abbrechen</v-btn>
          <v-btn color="primary" text type="submit" :disabled="!valid">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AmountTextField from "@/components/forms/AmountTextField";

export default {
  components: { AmountTextField },

  data: () => ({
    open: false,
    valid: false,

    addon: null,

    textRules: [v => !!v || "Muss ausgefüllt sein"]
  }),

  watch: {
    open(value) {
      if (value) {
        this.resetForm();
      }
    }
  },

  methods: {
    /**
     * Reset form.
     */
    resetForm() {
      this.addon = {
        id: null,
        name: null,
        price: 0,
        addon_items: []
      };
    },

    /**
     * Close modal
     */
    abort() {
      this.open = false;
    },

    /**
     * Save addon.
     */
    save() {
      this.$api.http
        .post(`backoffice/addons`, this.addon)
        .then(response => {
          this.open = false;
          this.$emit("saved");
        })
        .catch(error => {
          const message = error.response.data.message;
          this.$toast.error(message);
        });
    }
  }
};
</script>
