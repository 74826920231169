<template>
  <div class="addons mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">
            Add-Ons
          </h1>
        </v-col>
        <v-col cols="auto">
          <tooltip-button
            icon="add"
            text="Add-On erfassen"
            color="primary"
            @click="openCreateDialog()"
            classNames="ml-3"
            position="top"
          />
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            :url="'backoffice/addons'"
            :with="['addon_items']"
            :search-enabled="true"
            :headers="headers"
            ref="addonsDatatable"
          >
            <template v-slot:[`item.id`]="{ item }">
              <router-link :to="`/addons/${item.id}`">
                {{ item.id }}
              </router-link>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <router-link :to="`/addons/${item.id}`">
                {{ item.name }}
              </router-link>
            </template>
            <template v-slot:item.description="{ item }">
              {{ item.description | shorten }}
            </template>
            <template v-slot:item.price="{ item }">
              CHF {{ item.price | formatCurrency }}
            </template>
            <template v-slot:item.num_items_active="{ item }">
              <NumberLabel
                :num="item.addon_items.filter(i => !i.is_in_repair).length"
              />
            </template>
            <template v-slot:item.num_items_repair="{ item }">
              <NumberLabel
                :num="item.addon_items.filter(i => i.is_in_repair).length"
              />
            </template>
            <template v-slot:item.action="{ item }">
              <tooltip-button
                icon="delete"
                text="Löschen"
                color="default"
                :dark="false"
                @click="openDeleteDialog(item.id, item.name)"
                classNames="elevation-0 ml-1 mt-1 mb-1"
                position="top"
                size="x-small"
              />
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <addon-dialog
      ref="addonDialog"
      @saved="$refs.addonsDatatable.getDataFromApi()"
    />
    <delete-dialog
      ref="deleteDialog"
      @deleted="$refs.addonsDatatable.getDataFromApi()"
    />
  </div>
</template>

<script>
import TooltipButton from "@/components/forms/TooltipButton";
import RemoteDataTable from "@/components/RemoteDataTable";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import AddonDialog from "@/components/dialogs/AddonDialog";
import NumberLabel from "@/components/labels/NumberLabel";

export default {
  name: "addons",

  components: {
    TooltipButton,
    RemoteDataTable,
    DeleteDialog,
    AddonDialog,
    NumberLabel
  },

  filters: {
    shorten(value) {
      if (value) {
        if (value.length > 30) {
          return value.substr(0, 30) + "...";
        }
        return value;
      }
      return null;
    }
  },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Name", align: "left", sortable: true, value: "name" },
      {
        text: "Beschreibung",
        align: "left",
        sortable: true,
        value: "description"
      },
      { text: "Preis", align: "left", sortable: true, value: "price" },
      {
        text: "Einheiten in Betrieb",
        align: "left",
        sortable: true,
        value: "num_items_active"
      },
      {
        text: "Einheiten in Reperatur",
        align: "left",
        sortable: true,
        value: "num_items_repair"
      },
      {
        text: "Aktionen",
        align: "right",
        sortable: false,
        value: "action",
        width: 150
      }
    ],
    search: null
  }),

  methods: {
    /**
     * Open create dialog
     */
    openCreateDialog() {
      this.$refs.addonDialog.open = true;
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.entityId = id;
      this.$refs.deleteDialog.entityName = `#${id} ${name}`;
      this.$refs.deleteDialog.entityUrl = "addons";
      this.$refs.deleteDialog.open = true;
    }
  }
};
</script>

<style lang="scss">
.addons {
}
</style>
