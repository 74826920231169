var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addons mt-4 ml-8 mr-8"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"display-2 mb-5"},[_vm._v(" Add-Ons ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('tooltip-button',{attrs:{"icon":"add","text":"Add-On erfassen","color":"primary","classNames":"ml-3","position":"top"},on:{"click":function($event){return _vm.openCreateDialog()}}})],1)],1),_c('v-card',{staticClass:"elevation-0",attrs:{"color":"default","outlined":""}},[_c('v-card-text',[_c('remote-data-table',{ref:"addonsDatatable",attrs:{"url":'backoffice/addons',"with":['addon_items'],"search-enabled":true,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/addons/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/addons/" + (item.id))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shorten")(item.description))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" CHF "+_vm._s(_vm._f("formatCurrency")(item.price))+" ")]}},{key:"item.num_items_active",fn:function(ref){
var item = ref.item;
return [_c('NumberLabel',{attrs:{"num":item.addon_items.filter(function (i) { return !i.is_in_repair; }).length}})]}},{key:"item.num_items_repair",fn:function(ref){
var item = ref.item;
return [_c('NumberLabel',{attrs:{"num":item.addon_items.filter(function (i) { return i.is_in_repair; }).length}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"icon":"delete","text":"Löschen","color":"default","dark":false,"classNames":"elevation-0 ml-1 mt-1 mb-1","position":"top","size":"x-small"},on:{"click":function($event){return _vm.openDeleteDialog(item.id, item.name)}}})]}}],null,true)})],1)],1)],1),_c('addon-dialog',{ref:"addonDialog",on:{"saved":function($event){return _vm.$refs.addonsDatatable.getDataFromApi()}}}),_c('delete-dialog',{ref:"deleteDialog",on:{"deleted":function($event){return _vm.$refs.addonsDatatable.getDataFromApi()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }